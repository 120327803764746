<template>
    <section>
        <top-alternative-section
            :image="image"
            :title="$t('monitoreo.top.title')"
            :content="$t('monitoreo.top.content')"
        ></top-alternative-section>
        
        <features-section
          :title="$t('monitoreo.featuresSection.title')"
          :description="$t('monitoreo.featuresSection.description')"
          :items="$t('monitoreo.featuresSection.items')"
        >
        </features-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/planificacion.png"
  
  export default {
    name: "VALEPLUS Expenses",
    components: {
      TopAlternativeSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  