<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <base-business-info
            title="Contactanos"
            content="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo pariatur quidem, quis modi iure nihil commodi suscipit, corporis dicta adipisci, voluptatum quia cupiditate."
            :business="[
              {
                icon: 'mdi-map-marker-outline',
                title: 'Address',
                text: 'Av. Insurgentes Sur #64, Col. Juarez, CDMX',
              },
              {
                icon: 'mdi-cellphone',
                title: 'Phone',
                text: '+52 (55) 5254 7000',
              },
              {
                icon: 'mdi-email',
                title: 'Email',
                text: 'contacto@hubiter.biz',
              },
            ]"
          >
          </base-business-info>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-contact-form
            title="Envianos un email"
            href="mailto:contacto@hubiter.biz?subject=Contacto"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {}
</script>
